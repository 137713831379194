




















































































import { computed, defineComponent, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OUserGroupData: () => import('@/components/organisms/panel/user/o-user-group-data.vue'),
    OUserGroupSummary: () => import('@/components/organisms/panel/user/o-user-group-summary.vue')
  },

  setup(props, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
    }>({
      stepper: 1,
      data: {
        name: '',
        eventPanel: '',
        participants: '',
        lecturers: '',
        companies: '',
        abstracts: '',
        posterSession: '',
        surveys: '',
        accessControl: '',
        print: '',
        reports: '',
        info: '',
        eventConfig: '',
        registrationModule: '',
        accomodationModule: '',
        additionalServicesModule: '',
        abstractsModule: '',
        partnersModule: '',
        eventProgramModule: '',
        posterSessionModule: '',
        surveysModule: '',
        autoMessagesModule: '',
        companiesModule: '',
        receptionModule: '',
        siemens: ''
      }
    })


    const dataSummary = computed(() => ({
      data: model.data
    }))

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = []

        if (!model.data.name) errors.push({ step: 1, error: 'Podaj nazwę grupy.' })

        return errors
      })
    })

    const isStepEditable = () => {
      return true
    }

    const isStepComplete = (step: number) => {
      return model.stepper > step && !state.errors.find(error => error.step == step)
    }

    const isStepValid = (step: number) => {
      return !state.errors.find(error => error.step == step)
    }

    const isFormValid = computed(() => !state.errors.length)

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY
      let highest = Number.NEGATIVE_INFINITY

      let tmp: number

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step
        if (tmp < lowest) lowest = tmp
        if (tmp > highest) highest = tmp
      }

      model.stepper = lowest
    }

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      const data = {
        name: model.data.name,
        eventPanel: {
          alerts: model.data.eventPanel?.includes('alerts') || false
        },
        participants: {
          participantsList: model.data.participants?.includes('participantsList') || false,
          participantsManagement: model.data.participants?.includes('participantsManagement') || false
        },
        lecturers: {
          lecturersList: model.data.lecturers?.includes('lecturersList') || false,
          lecturersManagement: model.data.lecturers?.includes('lecturersManagement') || false
        },
        companies: {
          companiesList: model.data.companies?.includes('companiesList') || false,
          companiesManagement: model.data.companies?.includes('companiesManagement') || false
        },
        abstracts: {
          abstractsList: model.data.abstracts?.includes('abstractsList') || false,
          abstractsManagement: model.data.abstracts?.includes('abstractsManagement') || false
        },
        posterSession: {
          posterSessionList: model.data.posterSession?.includes('posterSessionList') || false
        },
        surveys: {
          surveysList: model.data.surveys?.includes('surveysList') || false
        },
        accessControl: {
          accessControlView: model.data.accessControl?.includes('accessControlView') || false,
          accessControlManagement: model.data.accessControl?.includes('accessControlManagement') || false
        },
        print: {
          printList: model.data.print?.includes('printList') || false,
          printManagement: model.data.print?.includes('printManagement') || false
        },
        reports: {
          reportsManagement: model.data.reports?.includes('reportsManagement') || false
        },
        info: {
          view: model.data.info?.includes('view') || false
        },
        eventConfig: {
          eventData: model.data.eventConfig?.includes('eventData') || false,
          graphicOptions: model.data.eventConfig?.includes('graphicOptions') || false,
          participantGroups: model.data.eventConfig?.includes('participantGroups') || false,
          discounts: model.data.eventConfig?.includes('discounts') || false,
          messages: model.data.eventConfig?.includes('messages') || false
        },
        eventModules: {
          registrationModule: {
            moduleData: model.data.registrationModule?.includes('moduleData') || false,
            moduleDegree: model.data.registrationModule?.includes('moduleDegree') || false,
            moduleFee: model.data.registrationModule?.includes('moduleFee') || false
          },
          accomodationModule: {
            moduleData: model.data.accomodationModule?.includes('moduleData') || false,
            moduleHotels: model.data.accomodationModule?.includes('moduleHotels') || false
          },
          additionalServicesModule: {
            moduleData: model.data.additionalServicesModule?.includes('moduleData') || false,
            moduleServices: model.data.additionalServicesModule?.includes('moduleServices') || false,
            moduleForms: model.data.additionalServicesModule?.includes('moduleForms') || false
          },
          abstractsModule: {
            moduleData: model.data.abstractsModule?.includes('moduleData') || false,
            moduleSessions: model.data.abstractsModule?.includes('moduleSessions') || false,
            modulePublications: model.data.abstractsModule?.includes('modulePublications') || false
          },
          partnersModule: {
            view: model.data.partnersModule?.includes('view') || false
          },
          eventProgramModule: {
            moduleProgram: model.data.eventProgramModule?.includes('moduleProgram') || false,
            moduleLocalization: model.data.eventProgramModule?.includes('moduleLocalization') || false
          },
          posterSessionModule: {
            view: model.data.posterSessionModule?.includes('view') || false
          },
          surveysModule: {
            view: model.data.surveyModule?.includes('view') || false
          },
          autoMessagesModule: {
            view: model.data.autoMessagesModule?.includes('view') || false
          },
          companiesModule: {
            moduleData: model.data.companiesModule?.includes('moduleData') || false,
            moduleCodes: model.data.companiesModule?.includes('moduleCodes') || false,
            moduleFees: model.data.companiesModule?.includes('moduleFees') || false,
            moduleHotels: model.data.companiesModule?.includes('moduleHotels') || false,
            moduleAdditionalServices: model.data.companiesModule?.includes('moduleAdditionalServices') || false
          },
          receptionModule: {
            moduleIdentities: model.data.receptionModule?.includes('moduleIdentities') || false,
            moduleCertificates: model.data.receptionModule?.includes('moduleCertificates') || false,
            moduleTerminals: model.data.receptionModule?.includes('moduleTerminals') || false,
            moduleKiosks: model.data.receptionModule?.includes('moduleKiosks') || false
          }
        },
        siemens: {
          reports: model.data.siemens?.includes('siemensReports') || false
        }
      }

      state.loading = true

      axiosInstance
        .post('user/auth/group', data)
        .then(() => {

          state.success = true
          model.stepper = 1
          model.data = {}
        })
        .catch(error => {
          state.error = error.response.status
        })
        .finally(() => state.loading = false)
    }

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403: return 'Brak uprawnień'
        case 404: return 'Endpointu nie znaleziono'
        case 409: return 'Grupa już istnieje'
        case 500: return `${root.$tc('layout.errors.500')}`
        default: return 'Wystąpił bład'
      }
    }

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage
    }
  }
})
